import React from "react"

function HideDraft() {
  return (
    <style>{`
      #drift-widget-container {
        display: none;
      }
    `}</style>
  )
}

export default HideDraft
