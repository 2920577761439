import { graphql } from "gatsby"
import React, { FC } from "react"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import SEO from "src/components/SEO"
import MenuFlow from "src/images/universal-app-icon-P3.png"
import Link from "src/utils/Link"
import { usePages } from "src/context/pages"
import HideDraft from "src/components/HideDraft"
import { useSignUp } from "src/hooks/useSignUp"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
  location,
}) => {
  const { currentPath } = usePages()
  const signUp = useSignUp(texts)

  return (
    <>
      <HideDraft />
      <SEO
        title={texts.metaTitle}
        description={texts.metaDescription}
        openGraph={{
          title: texts.metaTitle,
          description: texts.metaDescription,
          url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        }}
      />
      <main className={styles.main}>
        <div className={styles.container}>
          <Link className={styles.icon} to="/">
            <img src={MenuFlow} />
          </Link>
          <h1 className={styles.title}>{texts.verifyTitle}</h1>
          <p className={styles.separatorText}>{texts.sentEmail}</p>
          {location?.state?.email && (
            <p className={styles.tagEmail}>{location.state.email}</p>
          )}
          <p className={styles.separatorText}>{texts.followLink}</p>
          {location?.state?.email && (
            <form
              onSubmit={e => {
                e.preventDefault()
                if (location?.state?.email) {
                  signUp.signUp(location?.state?.email)
                }
              }}
            >
              <button
                className={`button button-second ${styles.resetButton}`}
                {...signUp.buttonProps}
              >
                {texts.resetButton}
              </button>
            </form>
          )}
        </div>
      </main>
    </>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        verifyTitle
        sentEmail
        followLink
        resetButton
      }
    }
  }
`

export default Template
