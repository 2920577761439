import { useCallback, useState, useRef } from "react"
import { apiRequest } from "src/api"
import { useUnmounted } from "./useUnmounted"
import { isEmailValid } from "src/utils/isEmailValid"
import { navigate } from "gatsby"

type InitialState = {
  status: "initial"
}

type ErrorState = {
  status: "error"
  error: string
}

type SuccessState = {
  status: "success"
}

type SignUpState = (ErrorState | InitialState | SuccessState) & {
  loading: boolean
}

export function useSignUp(texts: {
  invalidEmailError?: string
  unknownSignUpError?: string
}) {
  const emailRef = useRef<HTMLInputElement>(null)
  const [state, setState] = useState<SignUpState>(() => ({
    status: "initial",
    loading: false,
  }))

  const unmountedRef = useUnmounted()

  const signUp = useCallback(async (email = emailRef.current?.value || "") => {
    try {
      if (!isEmailValid(email)) {
        return setState(current => ({
          ...current,
          status: "error",
          error: texts.invalidEmailError || "invalidEmailError",
        }))
      }

      setState(current => ({
        ...current,
        loading: true,
      }))

      const response = await apiRequest(
        `signups`,
        {
          email,
        },
        {
          method: "POST",
          credentials: "include",
          headers: {
            "X-Requested-With": "Fetch",
          },
        },
      )

      if (unmountedRef.current) return

      if (response.status === 401) {
        window.location.href = `${process.env.GATSBY_APP_URL}/signup?account-exists`
      } else if (response.status >= 400) {
        const data = await response.json()

        if (unmountedRef.current) return

        const error = data.errors?.[0]

        if (error?.attribute === "email") {
          setState({
            status: "error",
            error: texts.invalidEmailError || "invalidEmailError",
            loading: false,
          })
        } else {
          setState({
            status: "error",
            error: data || texts.unknownSignUpError || "unknownSignUpError",
            loading: false,
          })
        }
      } else {
        navigate("/signup/confirm-email", {
          state: { email },
        })
      }
    } catch (error) {
      if (unmountedRef.current) return

      setState({
        status: "error",
        error: texts.unknownSignUpError || "unknownSignUpError",
        loading: false,
      })
    }
  }, [])

  const reset = useCallback(() => {
    setState({
      status: "initial",
      loading: false,
    })
  }, [])

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      signUp()
    },
    [signUp],
  )

  return {
    ...state,
    signUp,
    reset,
    onSubmit,
    formProps: {
      onSubmit,
    },
    inputProps: {
      onFocus: () => reset(),
      disabled: state.loading,
      ref: emailRef,
    },
    buttonProps: {
      disabled: state.loading || state.status === "error",
    },
  }
}
