export function apiRequest(endpoint: string, body: any, config: RequestInit) {
  return fetch(`${process.env.GATSBY_GETFLOW_API_ENDPOINT}/${endpoint}`, {
    ...config,
    body: body instanceof FormData ? body : JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.flow.v2+json",
      ...config.headers,
    },
  })
}
